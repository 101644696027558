import React from 'react'
import { useRouter } from 'next/router'
import Image from 'next/image'
import ErrorImage from '../public/images/oops.png'

const Custom404: React.FC = () => {
  const router = useRouter()
  return (
    <div className="error-page" id="main-content">
        <Image src={ErrorImage} alt="error" width={190} height={73.3} />
      <h2>We couldn’t pop that page. </h2>
      <button
        className="btn__primary"
        onClick={() => router.push('/categories')}>
        VIEW ALL CATEGORIES
      </button>
    </div>
  )
}

export default Custom404

export const getServerSideProps = async () => {
  return {
    props: {

    }
  }
}
